const basePath = process.env.PUBLIC_URL;
const LoaderImage = () => (
  <img
    alt="Loading"
    src={`${basePath}/images/icons/loader.png`}
    className="animate-spin h-5 w-5 m-auto"
  />
);

export default LoaderImage;
