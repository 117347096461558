import { FC, useContext, useEffect, useState } from "react";
import { decode } from "html-entities";
import { RWebShare } from "react-web-share";
import SocialMedia from "./SocialMedia";
import vCardJS from "vcards-js";
import axios from "axios";

type Props = {
  clientData: any;
  generalSettings: any;
};

const Header: FC<Props> = ({ clientData, generalSettings }) => {
  const menuButton = document.getElementById("hamburger_menu");
  const [adData, setAdData]: any = useState<any>();
  const [lockIcon, setLockIcon] = useState<any>();

  const iconItems = clientData.icons_order
    ? clientData.icons_order.split(",")
    : [];

  const getBase64FromUrl = async (url: any) => {
    const { data } = await axios({
      method: "GET",
      url,
      responseType: "blob",
    });

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  return (
    <div className="site-header sticky top-0">
      <div className="flex flex-col">
        <div className="w-full flex flex-row items-center justify-between mx-auto mt-2 relative ">
          {clientData.icon_petfriendly_active === "1" &&
            clientData.is_business === "1" &&
            generalSettings.icon_petfriendly && (
              <img
                src={generalSettings?.icon_petfriendly}
                height={50}
                width={50}
                alt=""
                className="social-icon-color-trans absolute max-w-[20%] left-[5vw]"
              ></img>
            )}
          {(clientData.is_business === "0" || clientData.is_business === "2") && (
            <img
              src={clientData?.QR_Code_image_URL}
              height={80}
              width={80}
              alt=""
              className="absolute max-w-[20%] left-[5vw]"
            ></img>
          )}
          {clientData.logo_url && (
            <div className="w-full flex flex-row justify-center">
              <img
                alt="Logo"
                src={clientData.logo_url}
                className="max-h-[17vh] max-w-[50vw]"
              />
            </div>
          )}
          {generalSettings?.icon_hamburger && (
            <img
              src={generalSettings?.icon_hamburger}
              height={50}
              width={50}
              alt=""
              className={`social-icon-color-trans fixed cursor-pointer right-[5vw] ${
                clientData.logo_url ? "" : "mt-12"
              }`}
              onClick={() => document.getElementById("hamburger_menu")?.click()}
            ></img>
          )}
        </div>
        <div
          className={`text-center text-600 theme-button-background theme-text-color ${
            clientData.logo_url ? "" : "mt-14"
          } `}
          dangerouslySetInnerHTML={{
            __html: decode(clientData.Title_tag_WebSite),
          }}
        ></div>
      </div>
      {/* {clientData.position_of_shared_icons === "top" && (
        <div className="flex flex-row justify-center items-center w-[85%] mx-auto relative mb-2 overflow-hidden">
          {clientData.icon_Instagram_active === "1" &&
            clientData.Client_Instagram_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_instagram"
                iconOrder={iconItems.indexOf("icon_instagram")}
                src={generalSettings.icon_instagram}
                href={clientData.Client_Instagram_URL}
              />
            )}
          {clientData.icon_Facebook_active === "1" &&
            clientData.Client_Facebook_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_facebook"
                iconOrder={iconItems.indexOf("icon_facebook")}
                src={generalSettings.icon_facebook}
                href={clientData.Client_Facebook_URL}
              />
            )}
          {clientData.icon_LinkedIn_active === "1" &&
            clientData.Client_LinkedIn_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_linkedin"
                iconOrder={iconItems.indexOf("icon_linkedin")}
                src={generalSettings.icon_linkedin}
                href={clientData.Client_LinkedIn_URL}
              />
            )}
          {clientData.icon_youtube_active === "1" &&
            clientData.icon_youtube_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_youtube_URL"
                iconOrder={iconItems.indexOf("icon_youtube_URL")}
                src={generalSettings.icon_youtube_URL}
                href={clientData.icon_youtube_URL}
              />
            )}
          {clientData.icon_pinterest_active === "1" &&
            clientData.icon_pinterest_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_pinterest"
                iconOrder={iconItems.indexOf("icon_pinterest")}
                src={generalSettings.icon_pinterest}
                href={clientData.icon_pinterest_URL}
              />
            )}
          {clientData.icon_tiktok_active === "1" &&
            clientData.client_tiktok_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_tiktok"
                iconOrder={iconItems.indexOf("icon_tiktok")}
                src={generalSettings.icon_tiktok}
                href={clientData.client_tiktok_URL}
              />
            )}
          {clientData.icon_twitter_active === "1" &&
            clientData.icon_twitter_client_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_twitter_image"
                iconOrder={iconItems.indexOf("icon_twitter_image")}
                src={generalSettings.icon_twitter_image}
                href={clientData.icon_twitter_client_URL}
              />
            )}
          {clientData.icon_call_us_active === "1" &&
            clientData.Icon_call_us_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_call_us"
                iconOrder={iconItems.indexOf("icon_call_us")}
                src={generalSettings.icon_call_us}
                href={clientData.Icon_call_us_URL}
              />
            )}
          {clientData.icon_whatsapp_active === "1" &&
            clientData.icon_whatsapp_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_whatsapp"
                iconOrder={iconItems.indexOf("icon_whatsapp")}
                src={generalSettings.icon_whatsapp}
                href={clientData.icon_whatsapp_URL}
              />
            )}
          {clientData.icon_signal_active === "1" &&
            clientData.icon_signal_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_signal"
                iconOrder={iconItems.indexOf("icon_signal")}
                src={generalSettings.icon_signal}
                href={clientData.icon_signal_URL}
              />
            )}
          {clientData.icon_telegram_active === "1" &&
            clientData.icon_telegram_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_telegram"
                iconOrder={iconItems.indexOf("icon_telegram")}
                src={generalSettings.icon_telegram}
                href={clientData.icon_telegram_URL}
              />
            )}
          
          {clientData.icon_skype_active === "1" &&
            clientData.icon_skype_URL && (
              <SocialMedia
                name="icon_skype"
                iconOrder={iconItems.indexOf("icon_skype")}
                src={generalSettings.icon_skype}
                href={clientData.icon_skype_URL}
              />
            )}
        </div>
      )} */}
    </div>
  );
};
export default Header;
