import React, { useEffect, useState } from "react";

const Instructions = (clientData: any) => {
  const app_name = clientData.clientData.app_name;

  return (
    <a
      className="flex cursor-pointer mt-20"
      href={`https://zaags.com/wp-content/uploads/zaags-instruction/${app_name}.csv`}
    >
      <p className="text-3xl flex mx-auto justify-center items-center font-bold">
        CSV download link
      </p>
    </a>
  );
};

export default Instructions;
