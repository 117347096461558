const basePath = process.env.PUBLIC_URL;
const Loader = () => (
  <div className="m-auto table mt-5">
    <img
      alt="Loading"
      src={`${basePath}/images/icons/loader.png`}
      className="animate-spin h-8 w-8"
    />
  </div>
);

export default Loader;
