import { FC, Key, useEffect, useState } from "react";
import { adsData, getLockData, getShareData } from "../core/_requests";
import AdComponent from "./AdComponent";
import SocialMedia from "./SocialMedia";
import axios from "axios";
import vCardJS from "vcards-js";

type Props = {
  clientData: any;
  generalSettings: any;
};
const Footer: FC<Props> = ({ clientData, generalSettings }) => {
  const [adData, setAdData]: any = useState<any>();
  const [lockIcon, setLockIcon] = useState<any>();
  const [shareIcon, setShareIcon] = useState<any>();


  useEffect(() => {
    adsData(clientData.clients_id).then((response) => {
      setAdData(response.data);
    });
    getLockData(clientData.clients_id).then((response) => {
      setLockIcon(response.data);
    });
  }, []);

  useEffect(() => {
    getShareData(clientData.clients_id).then((response) => {
      setShareIcon(response.data);
    });
  }, []);

  const hostName = process.env.REACT_APP_HOST;
  const basePath = process.env.PUBLIC_URL;

  const [googleAdd, setGoogleAdd] = useState<any>();

  const date1 = new Date();
  const date2 = new Date(clientData.subscription_date);

  useEffect(() => {
    const googleAdd = adData
      ?.map((item: any) =>
        item?.Google_Ads_Providers === "app_only"
          ? item
          : item?.Google_Ads_Providers === "both"
      )
      .find((item: any) => typeof item === "object");
    setGoogleAdd([googleAdd]);
  }, [adData]);

  const social_array = [
    clientData.icon_Instagram_active,
    clientData.icon_Facebook_active,
    clientData.icon_tiktok_active,
    clientData.icon_twitter_active,
    clientData.icon_youtube_active,
    clientData.icon_pinterest_active,
    clientData.icon_LinkedIn_active,
    clientData.icon_whatsapp_active,
    clientData.icon_skype_active,
    clientData.icon_telegram_active,
    clientData.icon_signal_active,
    clientData.icon_call_us_active,
  ];

  let count = 0;
  social_array.map((data) => {
    data === "1" ? count++ : (count = count);
  });

  const iconItems = clientData.icons_order
    ? clientData.icons_order.split(",")
    : [];

  const button = document.getElementById("notify");
  button?.addEventListener("click", () => {
    Notification.requestPermission().then((result) => {
      if (result === "granted") {
        console.log("Permission is granted");
        const notify = new Notification("First notification", {
          body: "Hello This is a notification",
        });
      } else {
        console.log("Permission is not granted");
      }
    });
  });

  const getBase64FromUrl = async (url: any) => {
    const { data } = await axios({
      method: "GET",
      url,
      responseType: "blob",
    });

    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(data);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  return clientData.Client_Status === "15" ||
    clientData.Client_Status === "17" ||
    clientData.Client_Status === "21" ? null : (
    <>
      {/* {clientData.position_of_shared_icons === "bottom" && (
        <div className="flex flex-row justify-center items-center w-[85%] mx-auto relative">
          {clientData.icon_Instagram_active === "1" &&
            clientData.Client_Instagram_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_instagram"
                iconOrder={iconItems.indexOf("icon_instagram")}
                src={generalSettings.icon_instagram}
                href={clientData.Client_Instagram_URL}
              />
            )}
          {clientData.icon_Facebook_active === "1" &&
            clientData.Client_Facebook_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_facebook"
                iconOrder={iconItems.indexOf("icon_facebook")}
                src={generalSettings.icon_facebook}
                href={clientData.Client_Facebook_URL}
              />
            )}
          {clientData.icon_LinkedIn_active === "1" &&
            clientData.Client_LinkedIn_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_linkedin"
                iconOrder={iconItems.indexOf("icon_linkedin")}
                src={generalSettings.icon_linkedin}
                href={clientData.Client_LinkedIn_URL}
              />
            )}
          {clientData.icon_youtube_active === "1" &&
            clientData.icon_youtube_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_youtube_URL"
                iconOrder={iconItems.indexOf("icon_youtube_URL")}
                src={generalSettings.icon_youtube_URL}
                href={clientData.icon_youtube_URL}
              />
            )}
          {clientData.icon_pinterest_active === "1" &&
            clientData.icon_pinterest_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_pinterest"
                iconOrder={iconItems.indexOf("icon_pinterest")}
                src={generalSettings.icon_pinterest}
                href={clientData.icon_pinterest_URL}
              />
            )}
          {clientData.icon_tiktok_active === "1" &&
            clientData.client_tiktok_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_tiktok"
                iconOrder={iconItems.indexOf("icon_tiktok")}
                src={generalSettings.icon_tiktok}
                href={clientData.client_tiktok_URL}
              />
            )}
          {clientData.icon_twitter_active === "1" &&
            clientData.icon_twitter_client_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_twitter_image"
                iconOrder={iconItems.indexOf("icon_twitter_image")}
                src={generalSettings.icon_twitter_image}
                href={clientData.icon_twitter_client_URL}
              />
            )}
          {clientData.icon_call_us_active === "1" &&
            clientData.Icon_call_us_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_call_us"
                iconOrder={iconItems.indexOf("icon_call_us")}
                src={generalSettings.icon_call_us}
                href={clientData.Icon_call_us_URL}
              />
            )}
          {clientData.icon_whatsapp_active === "1" &&
            clientData.icon_whatsapp_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_whatsapp"
                iconOrder={iconItems.indexOf("icon_whatsapp")}
                src={generalSettings.icon_whatsapp}
                href={clientData.icon_whatsapp_URL}
              />
            )}
          {clientData.icon_signal_active === "1" &&
            clientData.icon_signal_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_signal"
                iconOrder={iconItems.indexOf("icon_signal")}
                src={generalSettings.icon_signal}
                href={clientData.icon_signal_URL}
              />
            )}
          {clientData.icon_telegram_active === "1" &&
            clientData.icon_telegram_URL && (
              <SocialMedia
                lockIcon={lockIcon}
                name="icon_telegram"
                iconOrder={iconItems.indexOf("icon_telegram")}
                src={generalSettings.icon_telegram}
                href={clientData.icon_telegram_URL}
              />
            )}
          
          {clientData.icon_skype_active === "1" &&
            clientData.icon_skype_URL && (
              <SocialMedia
                name="icon_skype"
                iconOrder={iconItems.indexOf("icon_skype")}
                src={generalSettings.icon_skype}
                href={clientData.icon_skype_URL}
              />
            )}
        </div>
      )} */}
      {/* <div className="relative mb-2 flex flex-row items-center w-full text-center footer-text-color theme-bg-img">
        <a
          href="https://zaags.com"
          target="_blank"
          rel="noreferrer"
          className="mx-auto"
        >
          Powered by Zaags.com
        </a>
        
      </div>
      {googleAdd !== undefined && date1 > date2 && (
        <div className="google-ads">
          {googleAdd &&
            googleAdd?.length > 0 &&
            googleAdd?.map((i: any, index: Key | null | undefined) => {
              if (i?.Ads_Script_Content) {
                return (
                  <div key={index}>
                    <AdComponent
                      clientData={clientData}
                      generalSettings={generalSettings}
                      adSlot={i.Ads_Script_Content}
                    />
                  </div>
                );
              } else if (i) {
                return (
                  <div key={index}>
                    <a
                      href={
                        i.Ads_Link?.includes("https://") ||
                        i.Ads_Link?.includes("http://")
                          ? i.Ads_Link
                          : `https://${i.Ads_Link}`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {i.Ads_Image && (
                        <img
                          src={i?.Ads_Image}
                          alt="Ad"
                          className="h-[100px] max-w-full mx-auto object-contain object-bottom"
                        />
                      )}
                    </a>
                  </div>
                );
              }
            })}
        </div>
      )} */}
    </>
  );
};
export default Footer;
