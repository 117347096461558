import React, { lazy, Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Loader from "./components/Loader";
import {
  checkLogin,
  getAppGeneralSettings,
  getClientData,
  verifyExist,
} from "./core/_requests";
import Error404 from "./pages/Error404";
import packageJson from "../package.json";
import CssFilterConverter from "css-filter-converter";
import { toast } from "react-toastify";
import Review from "./pages/Review";
import RedirectLogin from "./pages/RedirectLogin";
import Slider from "./pages/Slider";
import Gallery from "./pages/Gallery";
import Camera from "./pages/Camera.js";
import Instructions from "./pages/Instructions";

const Home = lazy(() => import("./pages/Home"));

const App: React.FC = () => {
  const basePath = process.env.PUBLIC_URL;
  const pathArray = window.location.pathname.split("/");
  const id = pathArray[2];
  const [generalSettings, setGeneralSettings] = useState<any>([]);
  const [clientData, setClientData] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(true);
  const [register, setRegister] = useState<any>([]);

  document.documentElement.style.setProperty(
    "--design_Background_colour",
    clientData?.design_Background_colour
  );
  document.documentElement.style.setProperty(
    "--design_text_colour",
    clientData?.design_text_colour
  );
  document.documentElement.style.setProperty(
    "--design_button_back_colour",
    clientData?.design_button_back_colour
  );
  document.documentElement.style.setProperty(
    "--design_background_png_URL",
    `URL(${clientData?.design_background_png_URL})`
  );

  const result = CssFilterConverter.hexToFilter(
    clientData?.icon_color?.length > 7
      ? clientData?.icon_color?.slice(0, -2)
      : clientData?.icon_color
  );
  document.documentElement.style.setProperty(
    "--design_Icon_colour",
    result.color
  );

  const socialResult = CssFilterConverter.hexToFilter(
    clientData?.design_social_icon_color?.length > 7
      ? clientData?.design_social_icon_color?.slice(0, -2)
      : clientData?.design_social_icon_color
  );
  document.documentElement.style.setProperty(
    "--design_social_Icon_colour",
    socialResult.color
  );
  document.documentElement.style.setProperty(
    "--design_social_colour",
    clientData?.design_social_icon_color
  );

  let opacity: any;
  if (clientData.opacity >= 100) {
    opacity = 1;
  }
  opacity = 1 - Math.ceil(clientData.opacity / 10) * 0.1;
  document.documentElement.style.setProperty("--opacity", opacity);
  let back_opacity: any = Math.ceil(clientData.opacity / 10) * 0.1;
  document.documentElement.style.setProperty("--back_opacity", back_opacity);

  if (clientData.Client_Status === "25" || register === undefined) {
    if (!window.location.pathname.includes("redirect-login")) {
      window.open("https://zaags.com/deactivated/", "_self");
    }
  }

  useEffect(() => {
    //get general settings
    getAppGeneralSettings()
      .then((response) => {
        setGeneralSettings(response.data);
        generalSettings !== null && setLoader(false);
        const w: any = window;
        setTimeout(() => {
          w?.fixedCenterContent();
        }, 3000);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  /*   useEffect(() => {
    localStorage.setItem("appid", clientData.app_id);
  }, [clientData]); */

  useEffect(() => {
    // Get client data
    setLoader(true);
    getClientData(id)
      .then((response) => {
        setRegister(response.data);

        if (response.status === 200) {
          setClientData({ ...response.data, id: id });

          //set manifest file path
          document.getElementById &&
            document
              .getElementById("manifest_file")
              ?.setAttribute(
                "href",
                `${process.env.REACT_APP_API_URL}/manifest/${response?.data?.app_id}`
              );
        }
      })
      .catch((error) => {
        console.log(error);
      });
    /* .finally(() => {
        setLoader(false);
        const w: any = window;
        setTimeout(() => {
          w?.fixedCenterContent();
        }, 3000);
      }); */

    //remove the browser cache.
    const caching = () => {
      let version = localStorage.getItem("version");
      if (version != packageJson.version) {
        if ("caches" in window) {
          caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach((name) => {
              caches.delete(name);
            });
          });

          // Makes sure the page reloads. Changes are only visible after you refresh.
          window.location.reload();
        }

        localStorage.clear();
        localStorage.setItem("version", packageJson.version);
      }
    };

    caching();
  }, []);

  const isProduction = process.env.NODE_ENV === "production";

  useEffect(() => {
    const mail = window.location.href.includes("=")
      ? window.location.href.split("=")[1].replace("#", "")
      : clientData.contat_email;
    const data = {
      email: mail,
    };
    window.location.href.includes("verified") &&
      verifyExist(data).then((response) => {
        toast.success(response.message);
      });

    let interval = setInterval(() => {
      mail && sessionStorage.setItem("mail", JSON.stringify(data));
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  if (!loader) {
    if (register !== null || register !== undefined) {
      return (
        <div className="body">
          {!window.location.pathname.includes("camera") && (
            <Header clientData={clientData} generalSettings={generalSettings} />
          )}
          <div className="p-2 center-section bodyContent">
            <Router>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route
                    path={`${basePath}/redirect-login`}
                    element={<RedirectLogin />}
                  />
                  <Route
                    path={`${basePath}/:id`}
                    element={
                      <Home
                        generalSettings={generalSettings}
                        clientData={clientData}
                        setClientData={setClientData}
                      />
                    }
                  />
                  <Route
                    path={`${basePath}/:id/review`}
                    element={
                      <Review
                        clientData={clientData}
                        generalSettings={generalSettings}
                      />
                    }
                  />
                  <Route
                    path={`${basePath}/:id/slider`}
                    element={
                      <Slider
                        clientData={clientData}
                        setClientData={setClientData}
                        generalSettings={generalSettings}
                      />
                    }
                  />
                  <Route
                    path={`${basePath}/:id/gallery`}
                    element={
                      <Gallery
                        clientData={clientData}
                        generalSettings={generalSettings}
                      />
                    }
                  />
                  <Route
                    path={`${basePath}/:id/instructions`}
                    element={
                      clientData.is_bnb === "1" ? (
                        <Instructions clientData={clientData} />
                      ) : (
                        <Error404
                          clientData={clientData}
                          generalSettings={generalSettings}
                        />
                      )
                    }
                  />
                  <Route
                    path={`${basePath}/:id/camera`}
                    element={
                      <Camera
                        clientData={clientData}
                        setClientData={undefined}
                        setCamera={undefined}
                        generalSettings={generalSettings}
                      />
                    }
                  />
                  <Route
                    path="*"
                    element={
                      <Error404
                        clientData={clientData}
                        generalSettings={generalSettings}
                      />
                    }
                  />
                </Routes>
              </Suspense>
            </Router>
          </div>
          <Footer clientData={clientData} generalSettings={generalSettings} />
        </div>
      );
    } else {
      return (
        <>
          <Error404 clientData={clientData} generalSettings={generalSettings} />
          <Footer clientData={clientData} generalSettings={generalSettings} />
        </>
      );
    }
  } else {
    return (
      <>
        <Loader />
      </>
    );
  }
};

export default App;
