import React, { FC } from "react";

type Props = {
  clientData: any;
  generalSettings: any;
};

const Error404: FC<Props> = ({ clientData, generalSettings }) => {

window.open("https://zaags.com/deactivated/","_self")

  return (
    <>
      <div className="w-full h-screen justify-center items-center bg-white shadow-lg rounded-lg flex flex-col align-middle">
        <div className="w-full p-4 justify-start flex flex-col ">
          
        </div>
      </div>
    </>
  );
};

export default Error404;
