import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "../components/Loader";
import { checkLogin, loginDetail } from "../core/_requests";
import { toast } from "react-toastify";

const RedirectLogin = () => {
  const { loginWithRedirect, user } = useAuth0();
  const appId = localStorage.getItem("appid");
  const phoneNumber = localStorage.getItem("number");
  const photo = localStorage.getItem("photo");
  setTimeout(() => {
    appId && window.location.replace(`https://zaags.com/app/${appId}`);
  }, 5000);

  const LINKEDIN_CLIENT_SECRET = "BsW8vDGuXal1gTdo";
  const LINKEDIN_CLIENT_ID = "77dp0savtsn6qf";
  const LINKEDIN_CALLBACK_URL = "https://zaags.com/app/redirect-login";
  // const LINKEDIN_CALLBACK_URL = "http://localhost:3000/redirect-login";
  const linkedinOAuthURL = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${LINKEDIN_CLIENT_ID}&redirect_uri=${encodeURIComponent(
    LINKEDIN_CALLBACK_URL
  )}&scope=openid%20profile%20email`;

  const handleLogin = async (code: any) => {
    // Exchange the code for an access token
    const data = await fetch("https://www.linkedin.com/oauth/v2/accessToken", {
      method: "POST",
      body: new URLSearchParams({
        grant_type: "authorization_code",
        code,
        redirect_uri: LINKEDIN_CALLBACK_URL,
        client_id: LINKEDIN_CLIENT_ID,
        client_secret: LINKEDIN_CLIENT_SECRET,
      }),
    }).then((response) => response.json());

    const accessToken = data.access_token;

    const loginData = {
      social_name: "linkedin",
      access_token: accessToken,
    };

    loginDetail(loginData).then((res) => {
      const linkedInData = {
        email: res.email,
        token: accessToken,
        name: res.name,
        profile_img: photo ? photo : res.picture,
        social_type: "linkedin",
        email_verified: res.email_verified,
        family_name: res.family_name,
        given_name: res.given_name,
        locale: res.locale
          ? `${res.locale.language}-${res.locale.country}`
          : "",
        phone_number: phoneNumber,
      };

      const userLoginData = {
        social_type: "linkedin",
        email: res.email,
        family_name: res.family_name,
        given_name: res.given_name,
        email_verified: res.email_verified,
        locale: res.locale
          ? `${res.locale.language}-${res.locale.country}`
          : "",
        name: res.name,
        profile_img: photo ? photo : res.picture,
      };
      sessionStorage.setItem("data", JSON.stringify(userLoginData));
      checkLogin(linkedInData)
        .then((response) => {
          if (response.code === 200) {
            toast.success("Login Successfull", {});
            localStorage.removeItem("photo");
          } else if (response.status === 201) {
            toast.error(response?.message, {});
          } else {
            toast.error("Something went wrong.", {});
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const handleLinkedInCallback = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    if (code) handleLogin(code);
  };

  React.useEffect(() => {
    handleLinkedInCallback();
  }, []);

  return (
    <div className="absolute top-[50%] w-full">
      <h1 className="text-3xl text-center font-bold flex justify-center w-full">
        Please wait while we save your data; you will be redirected
        automatically
      </h1>
      <Loader />
    </div>
  );
};

export default RedirectLogin;
